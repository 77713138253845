<template>
  <div>
    <div
      v-for="(item, index) of prepareChips(url)"
      :key="index"
      style="float: left;"
    >
      <v-chip
        small
        style="margin-right: 2px;"
        outlined
        label
        color="white"
        dark
        disabled
      >
        <span style="color: black;">/</span>
      </v-chip>

      <v-chip
        label
        small
        style="margin-right: 2px;"
        :color="item.color"
        @click="item.active ? handleClick(item.param) : null" :style="{cursor:item.active?'pointer':'default'}">
        {{item.name}}
      </v-chip>
    </div>
    <v-dialog
      v-model="dialog"
      max-width="800"
    >
      <v-card>
        <v-card-title 
          class="text-h5"
          style="margin-left: 10px;"
        >
          Tipo de consulta
        </v-card-title>

        <v-card-text>
          <v-btn
            v-if="['dtouch', 'booking', 'players', 'dviewcontent'].indexOf(environment) >= 0"
            color="primary"
            elevation="2"
            outlined
            @click="handleGetInfo('workspace')"
            style="margin-left: 10px;"
          >
            workspace
          </v-btn>
          <v-btn
            v-if="['dtouch'].indexOf(environment) >= 0"
            color="primary"
            elevation="2"
            outlined
            @click="handleGetInfo('menu')"
            style="margin-left: 10px;"
          >
            menu
          </v-btn>
          <v-btn
            v-if="['dtouch'].indexOf(environment) >= 0"
            color="primary"
            elevation="2"
            outlined
            @click="handleGetInfo('content')"
            style="margin-left: 10px;"
          >
            content
          </v-btn>
          <v-btn
            v-if="['cm-api'].indexOf(environment) >= 0"
            color="primary"
            elevation="2"
            outlined
            @click="handleGetInfo('user')"
            style="margin-left: 10px;"
          >
            user
          </v-btn>
          <v-btn
            v-if="['dviewcontent'].indexOf(environment) >= 0"
            color="primary"
            elevation="2"
            outlined
            @click="handleGetInfo('content')"
            style="margin-left: 10px;"
          >
            content
          </v-btn>
          <v-btn
            v-if="['players'].indexOf(environment) >= 0"
            color="blue"
            elevation="2"
            outlined
            @click="handleGetInfo('totem')"
            style="margin-left: 10px;"
          >
            totem
          </v-btn>
          <v-btn
            v-if="['players'].indexOf(environment) >= 0"
            color="green"
            elevation="2"
            outlined
            @click="handleGetInfo('android')"
            style="margin-left: 10px;"
          >
            android
          </v-btn>
          <br/>
          <br/>
          {{result}}
        </v-card-text>

        <v-card-actions>
          <v-spacer />
          <v-btn
            color="primary"
            @click="handleClose"
          >
            Cerrar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import api from '@/services/api'
export default {
  props: {
    url: {
      type: String,
      required: true,
    },
    environment: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    dialog: false,
    selectedParam: null,
    result: null,
  }),
  methods: {
    prepareChips (v) {
      let aux = v.split('/').filter(x => x && x !== '')
      for (const item of aux.filter(x => x.indexOf('?') >= 0)) {
        aux = aux.filter(x => x !== item)
        const q = item.split('?')
        aux.push(q.shift())
        aux.push('?')
        aux.push(q.shift())
      }
      return aux.filter(x => x && x !== '').map(item => {
        const param = item
        return {
          color: param === '?' ? 'orange' : param.split('-').length === 5 ? '#ddeeba' : 'gray',
          param,
          name: param.indexOf('token') >= 0 ? param.split('token').shift().concat('token=to long...') : param,
          active: param.split('-').length === 5 ? true : false,
        }
      })
    },
    handleClose () {
      this.selectedParam = null
      this.result = null
      this.dialog = false
    },
    handleClick(v) {
      this.selectedParam = v
      this.dialog = true
    },
    handleGetInfo (type) {
      const filter = {
        type,
        param: this.selectedParam
      }
      api.put(this.environment, 'v1/system/logs/url-info', filter)
        .then(response => {
          this.result = response.data ? response.data.result : null
        })
    },
  },
}
</script>

